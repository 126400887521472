export const PERMISSIONTYPES = {
  usageAccessPermission: 'android.permission.PACKAGE_USAGE_STATS',
  notifications: 'android.permission.POST_NOTIFICATIONS',
};

export const SETTINGTYPES = {
  notifications: 'NOTIFICATIONS',
};

export const FRAUDTYPES = {
  modApp: 'mod_app',
  emulator: 'emulator',
  rooted: 'rooted',
  vpn: 'vpn',
  clone: 'clone',
  developerOptions: 'developer_options',
  blacklistedApp: 'bl_apps',
  lessAppsDetected: 'less_apps_detected',
};

export const activeFraudCheckList = [
  FRAUDTYPES.vpn,
  FRAUDTYPES.developerOptions,
  FRAUDTYPES.emulator,
  FRAUDTYPES.modApp,
  FRAUDTYPES.rooted,
  FRAUDTYPES.clone,
  FRAUDTYPES.blacklistedApp,
  FRAUDTYPES.lessAppsDetected,
];

export const ANDROID_LISTENER_EVENTS = {
  onResume: 'ON_RESUME',
  onPause: 'ON_PAUSE',
};
